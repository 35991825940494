<template>
  <v-card>
    <v-card-title class="text-h5 primary--text">
      {{ isNew ? "Novo Cliente" : "Editar Cliente" }}

      <v-spacer />

      <label for="status" class="text-body-2 mr-2">{{
        client.status ? "Ativo" : "Inativo"
      }}</label>
      <v-switch id="status" v-model="client.status" />
    </v-card-title>

    <v-card-text>
      <form class="py-5">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="client.name"
              color="primary"
              label="Nome"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="client.trading_name"
              color="primary"
              label="Razão Social"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="client.cep"
              outlined
              label="CEP"
              v-mask="'#####-###'"
              placeholder="xxxxx-xxx"
              dense
              @blur="getAddress"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="client.address"
              outlined
              label="Endereço"
              placeholder="Digite o nome da rua"
              dense
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="client.number"
              outlined
              label="N°"
              v-mask="'########'"
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="client.district"
              outlined
              label="Bairro"
              dense
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="client.state_uf"
              outlined
              label="Estado"
              dense
            />
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="client.city" outlined label="Cidade" dense />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="client.contact"
              color="primary"
              label="Contato"
              v-mask="['(##) ####-####', '(##) #####-####']"
              outlined
              dense
            />
          </v-col>
          <v-col cols="2">
            <v-checkbox
              color="primary"
              label="Whatsapp"
              v-model="client.is_whatsapp"
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="client.site"
              type="text"
              color="primary"
              label="Site"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn v-if="isNew" color="primary" @click="create">
        <v-icon small>mdi-check</v-icon>
        Cadastrar
      </v-btn>
      <v-btn v-else color="primary" @click="update">
        <v-icon small>mdi-check</v-icon>
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    isNew: Boolean,
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showPass: false,
    profiles: [],
  }),
  methods: {
    create() {
      this.$emit("create");
      this.$emit("close");
    },
    update() {
      this.$emit("update");
      this.$emit("close");
    },
    getAddress() {
      this.$api
        .get(`https://ws.apicep.com/cep.json?code=${this.client.cep}`)
        .then((res) => {
          let data = res.data;
          console.log(data);
          this.client.cep = data.code;
          this.client.address = data.address;
          this.client.district = data.district;
          this.client.state_uf = data.state;
          this.client.city = data.city;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>